.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
	/* padding: 5px 16px; */
	padding: 5px 12px;
}

.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters {
	/* padding-right: 30px !important; */
	padding-right: 22px !important;
}

.ant-table-thead > tr > th .ant-table-column-sorter {
	/* position: absolute; */
	/* right: 6px; */
	right: 2px;
	/* top: 50%;
	width: 14px;
	height: 17px;
	margin-top: -8.5px;
	text-align: center;
	color: #bfbfbf;
	transition: all 0.3s; */
}
